import Vue from 'vue'
import App from './App.vue'
import router from "@/router";
import 'vant/lib/index.css';
import vant from 'vant'
import store from './store'
import {debounce, getSchool, throttle,} from '@/utils/throttleAndDebounce'

import VueClipboard from 'vue-clipboard2'

Vue.use(VueClipboard)
//防抖
Vue.prototype.$throttle = throttle;
//节流
Vue.prototype.$debounce = debounce
//获取学校
Vue.prototype.$getSchool = getSchool
Vue.use(vant);
Vue.config.productionTip = false
// Vue.prototype.$on = function (event,callback ){
//   // console.log('event',event)
//   // console.log('callback',callback)
// }

new Vue({
  render: h => h(App),
  router,
  store,
  beforeCreate() {
    Vue.prototype.$bus = this
  }
}).$mount('#app')
