// 节流
let timer, flag;
export function throttle(func, wait = 300, immediate = true) {
    if (immediate) {
        if (!flag) {
            flag = true;
            // 如果是立即执行，则在wait毫秒内开始时执行
            typeof func === 'function' && func();
            timer = setTimeout(() => {
                flag = false;
            }, wait);
        }
    } else {
        if (!flag) {
            flag = true
            // 如果是非立即执行，则在wait毫秒内的结束处执行
            timer = setTimeout(() => {
                flag = false
                typeof func === 'function' && func();
            }, wait);
        }

    }
};
// 防抖
let timeout;
export function debounce(func, wait = 300, immediate = false) {
    if (timeout !== null) clearTimeout(timeout);
    if (immediate) {
        var callNow = !timeout;
        timeout = setTimeout(function() {
            timeout = null;
        }, wait);
        if (callNow) typeof func === 'function' && func();
    } else {
        // 设置定时器，当最后一次操作后，timeout不会再被清除，所以在延时wait毫秒后执行func回调方法
        timeout = setTimeout(function() {
            typeof func === 'function' && func();
        }, wait);
    }
}
//学校查询
export  async function getSchool(List,value = ''){
    let obj ={
        arr:[],
        option:[],
    }
    let regStr =  '';
    let states = true
    // if(value == '') return  false

    // if(null === value || undefined === value ) {
    //     console.log('if')
    //      obj.arr =   List
    //      obj.option =   List
    //     console.log('arr',obj.arr)
    //     console.log('option',obj.option)
    // }else{
        for(let i =0 ;i < value.length;i++) {
            if(value[i] == '市'){
                states = false
                regStr = regStr + '(' + value[i] + ')([\\s]*)'
            }else{
                states = true
                regStr = regStr + '(' + value[i] + ')([\\s]*)'
            }
        }
        let reg = new RegExp(regStr)
        // console.log('reg',reg)
        for (let i = 0; i< List.length;i++){
            let name =  states? List[i].name : List[i].city
            let regMatch =  name.match(reg);
            if(null !== regMatch) {// 将匹配的数据放入结果列表中
                obj.arr.push(List[i]);
            }
        }
        for(let item in obj.arr){
            obj.option.push({
                text:obj.arr[item].name,
                value:item,
                name:obj.arr[item].name,
                id:obj.arr[item].id
            })
        }
    // }
    return  obj
}
