<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>

export default {
  name: 'App',
  mounted() {

  }

}
</script>

<style lang="css">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  width: 100%;
  height: 100%;
}
body{
  background: #131313;
}
input{
  background: white;
}
*{
  margin: 0;
  padding: 0;
}
body  .sp-header{
  display: none;
}
body .sp-input-container div.sp-input{
  border: 1px solid black;
}
/*#app > div > div.van-popup.van-popup--round.van-popup--bottom > div > div.popup-content > div:nth-child(1) > div.popup-content-inputBox > div > div > div:nth-child(1) > div{*/
/*  border: 1px solid black;*/
/*}*/
 #app  .sp-input {
  border: 0px solid  #ebedf0 ;
  border-right:none ;
  border-left:none ;
  border-radius: 0;
}

</style>
