import Router from 'vue-router'
import Vue from 'vue'

Vue.use(Router)
const  router = new Router({
    mode:'hash',
    routes:[
        {
            //首页
            name:'home',
            path:'/',
            component:()=> import('../pages/view/home/home')
        },
        {
            //登录
            name:'login',
            path:'/login',
            component:()=> import('../pages/view/login/login')
        },
        {
            //注册
            name:'register',
            path:'/register',
            component:()=> import('../pages/view/register/register')
        },
        {
            //修改密码
            name:'changPwd',
            path:'/changPwd',
            component:()=> import('../pages/view/changPwd/changPwd')
        },
        {
            //免责声明
            name:'disclaimers',
            path:'/disclaimers',
            component:()=> import('../pages/view/disclaimers/index')
        },
        {
            //抽到的纸条
            name:'drawNote',
            path:'/drawNote',
            component:()=> import('../pages/view/drawNote/index')
        },
    ]
})

export default router