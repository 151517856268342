import vuex from 'vuex'
import vue from 'vue'
vue.use(vuex)

const store = new vuex.Store({
    state:{
        token:'',//token
        openid:'',//openid
        orderNumber:'',//订单号
        gender:'',//性别 1男 0女
    },
})

export  default  store
